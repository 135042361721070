import React from "react";
import LazyLoad from "react-lazyload";

const Twotwo = () => (
  <section
    id="two-col-section"
    className="two-col-section two-col-section--limit"
  >
    <div className="row container">
      <div className="col-md-6 col-xs-12 two-col-section__left hide-xs show-md">
        <LazyLoad height={519} offset={200}>
          <img
            className="two-col-section--limit__ront"
            alt="PM 2.5 için neden ulusal sınır değer yok?"
            width="519"
            height="519"
            src="/images/ront.jpg"
          />
        </LazyLoad>
      </div>
      <div className="col-md-6 col-xs-12 two-col-section__right">
        <h2>
          <strong>PM 2.5 için neden</strong>
          <br />
          ulusal sınır değer yok?
        </h2>
        <LazyLoad height={240} offset={200}>
          <img
            className="two-col-section--limit__rontM hide-md show-xs"
            alt="PM 2.5 için neden ulusal sınır değer yok?"
            width="327"
            height="240"
            src="/images/m_ront.jpg"
          />
        </LazyLoad>
        <p>
          <strong>
            2019’da Birleşmiş Milletler tüm hükümetlere bir çağrıda bulundu:
            “Hava kirliliği ve iklim krizleri birlikte ele alınmalı”. Bu çağrı
            gösteriyor ki hava kirliliği konusunda bilinç artıyor.{" "}
          </strong>
          Ülkemizde de hava kirliliği en önemli çevre sorunu olarak görülüyor.
          Türkiye’de henüz neden bir PM 2.5 sınır değeri olmadığı konusunda
          Çevre ve Şehircilik Bakanlığı’ndan verilen cevaba göre
          <br />
          <strong>
            “Yönetmelik henüz taslak aşamasında, ne zaman yürürlüğe gireceği
            belirsiz.”
          </strong>
        </p>
        <p>
          Bu belirsizlik bir son bulmalı! Bunu hep birlikte başarabilir, derin
          bir nefes alabiliriz. <br />
          Çünkü temiz hava haktır ve daha fazla beklemez.
        </p>
        <a href="#form" className="signed">
          İMZA VER
        </a>
      </div>
    </div>
  </section>
);

export default Twotwo;
