import React from "react";
import LazyLoad from "react-lazyload";

import "./index.scss";

const Pm = () => (
  <section id="pm" className="pm">
    <div className="container">
      <h2>PM 2.5 Nedir?</h2>
      <p>
        2.5μm (mikron)dan küçük hava kirleticilere{" "}
        <strong>Partikül Madde 2.5 (PM 2.5)</strong> denir.
      </p>
      <LazyLoad offset={300}>
        <div className="pm25diagram">
          <img
            className="pm25diagram__desktop"
            src="/images/pm25diagram-desktop.png"
            srcSet="/images/pm25diagram-desktop@2x.png 2x"
            alt="PM 2.5 Nedir?"
          ></img>
          <img
            className="pm25diagram__mobile"
            src="/images/pm25diagram-mobile.png"
            srcSet="/images/pm25diagram-mobile@2x.png 2x"
            alt="PM 2.5 Nedir?"
          ></img>
        </div>
      </LazyLoad>
      <div className="pm__table row">
        <div className="col-xs-12">
          Bir metreküp havanın içindeki oranına göre ölçümlenir.
        </div>
        <div className="col-xs-6">Gözle Görülmez</div>
        <div className="col-xs-6">Kilometrelerce yol alabilir</div>
        <div className="col-xs-6">Solunum yoluyla kana karışabilir</div>
        <div className="col-xs-6">Ana kaynağı fosil yakıtlardır.</div>
      </div>
      <a href="#form" className="signed">
        İMZA VER
      </a>
    </div>
    <LazyLoad offset={300}>
      <picture className="what-pm">
        <source
          media="(max-width: 638px)"
          srcSet="/images/m_what-pm.jpg"
        ></source>
        <img
          src="/images/pm-bg.jpg"
          alt="PM 2.5 Nedir?"
        ></img>
      </picture>
    </LazyLoad>
  </section>
);

export default Pm;
