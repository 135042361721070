import React from "react";

import "./index.scss";

const Header = () => (
  <header className="header">
    <div className="container">
      <div className="row middle-xs">
        <div className="col-md-6 col-xs-12">
          <span id="logo" className="header__logo">
            <img
              className="logo"
              alt="Havada Kalmasın | Greenpeace"
              src="/images/gp-logo.svg"
            />
          </span>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
