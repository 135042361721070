import React from "react";

import "./index.scss";

const Footer = ({ hclass }) => (
  <footer className={`footer ${hclass}`} id="footer">
    <div className="container row bottom-xs">
      <div className="col-md-8 col-xs-12">
        <strong>Greenpeace Akdeniz</strong>
        <address>
          Meşrutiyet Mah. Ebe Kızı Sok. Sosko İş Merkezi No:16/B28 Şişli 34363
          İstanbul{" "}
        </address>
        <u>(212) 292 76 19 / 20</u>
        <br />
      </div>
      <div className="col-md-4 col-xs-12 text-right">
        <a
          className="target"
          href="https://www.greenpeace.org/turkey/gizlilik-politikasi/"
        >
          Gizlilik Politikası
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
