import React from "react";
import Cookies from "universal-cookie";
import $ from "jquery";

import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import Pm from "../components/pm";
import TwoOne from "../components/twoone";
import TwoTwo from "../components/twotwo";
import TwoThree from "../components/twothree";
import Breath from "../components/breath";
import Citys from "../components/citys";
import Numbers from "../components/numbers";
import Bar from "../components/bar";

import Footer from "../components/footer";
import Loader from "../components/loader";

const cookies = new Cookies();
class Home extends React.Component {
  componentDidMount() {
    var ua = (function() {
      var a = {};
      var b = window.navigator;
      var c = [
        {
          property: "userAgent",
          regex: /iPhone/i,
          identity: "iPhone",
        },
        {
          property: "userAgent",
          regex: /iPod/i,
          identity: "iPod",
        },
        {
          property: "userAgent",
          regex: /iPad/i,
          identity: "iPad",
        },
        {
          property: "userAgent",
          regex: /Blackberry/i,
          identity: "Blackberry",
        },
        {
          property: "userAgent",
          regex: /Android/i,
          identity: "Android",
        },
        {
          property: "userAgent",
          regex: /Windows Phone/i,
          identity: "WindowsPhone",
        },
        {
          property: "userAgent",
          regex: /Android|webOS|iPhone|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i,
          identity: "Mobile",
        },
        {
          property: "platform",
          regex: /Mac/i,
          identity: "Mac",
        },
        {
          property: "platform",
          regex: /Win/i,
          identity: "Windows",
        },
        {
          property: "platform",
          regex: /Linux/i,
          identity: "Linux",
        },
        {
          property: "platform",
          regex: /Mac|Win|Linux/i,
          identity: "Desktop",
        },
        {
          property: "userAgent",
          regex: /Trident/i,
          identity: "MSIE",
        },
        {
          property: "userAgent",
          regex: /Firefox/i,
          identity: "Firefox",
        },
        {
          property: "userAgent",
          regex: /Chrome/i,
          identity: "Chrome",
        },
        {
          property: "userAgent",
          regex: /Safari/i,
          identity: "Safari",
        },
      ];
      for (var d = 0, e = c.length, f; d < e; d++) {
        f = c[d];
        a[f.identity] = f.regex.test(b[f.property]);
      }
      a.AndroidTablet = a.Android && !/mobile/i.test(b.userAgent);
      a.tablet = a.iPad || a.AndroidTablet;
      return a;
    })();
    if (ua.iPhone | ua.iPod | ua.iPad) var os = " ios ";
    else if (ua.Android) os = " android ";
    else if (ua.Blackberry) os = " blackberry ";
    else if (ua.WindowsPhone) os = " windowsphone ";
    else if (ua.Mac) os = " mac ";
    else if (ua.Windows) os = " windows ";
    else if (ua.Linux) os = " linux ";
    else os = " otheros ";
    if (ua.Mobile) var device = " mobile ";
    else if (ua.tablet) device = " tablet ";
    else if (ua.Desktop) device = " desktop ";
    else device = " otherdevice ";
    var browser = "";
    if (ua.MSIE) browser = " msie ";
    else if (ua.Firefox) browser = " firefox ";
    else if (ua.Chrome) browser = " chrome ";
    else if (ua.Safari) browser = " safari ";
    else browser = " otherbrowser ";
    function addHtmlClass(a, b, c) {
      var d = document.getElementsByTagName("body")[0];
      if (d.className.indexOf(c) === -1)
        d.className = d.className + a + " " + c + " " + b;
    }
    addHtmlClass(device, os, browser);

    // URL Parametreleri
    // alınıyor (UTM)
    function getParameter(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    // URL Parametre
    // değişkenleri
    var allParameters = window.location.search;
    var UTMsource = getParameter("utm_source");
    var UTMmedium = getParameter("utm_medium");
    var UTMcampaign = getParameter("utm_campaign");
    var UTMcontent = getParameter("utm_content");
    var UTMterm = getParameter("utm_term");
    //var phone = getParameter('p');
    //var phoneParameter = '?p='+phone;
    //function utmFull(utmType){
    //  var comeParameter = getParameter('utm_'+utmType)
    //  if(comeParameter !== ''){
    //    var ParaResult = '?utm_'+utmType+'='+comeParameter+'&';
    //    return ParaResult
    //  }else{
    //    var emptyPara = ''
    //    return emptyPara
    //  }
    //}

    // Eğer gelen veri varsa inputlara yazdır
    if (UTMsource) {
      $("#utm_source").val(UTMsource);
    } else {
      $("#utm_source").remove();
    }
    if (UTMmedium) {
      $("#utm_medium").val(UTMmedium);
    } else {
      $("#utm_medium").remove();
    }
    if (UTMcampaign) {
      $("#utm_campaign").val(UTMcampaign);
    } else {
      $("#utm_campaign").remove();
    }
    if (UTMcontent) {
      $("#utm_content").val(UTMcontent);
    } else {
      $("#utm_content").remove();
    }
    if (UTMterm) {
      $("#utm_term").val(UTMterm);
    } else {
      $("#utm_term").remove();
    }

    // Target linkler tıklandığında
    // ayrı pencerede açılmasını sağlar
    $(".target").click(function(a) {
      a.preventDefault();
      window.open(this.href);
    });

    // Anchor scroll smooth
    $('a[href^="#"]').on("click", function(event) {
      event.preventDefault();
      var hash = this.hash;
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top - 50,
        },
        800,
        function() {
          $("#eposta").focus();
        }
      );
    });

    // IP based GeoLocation Checkpoint
    let endpoint = new URL(process.env.IP_API_BASE);
    endpoint.searchParams.set("fields", "status,message,regionName");
    endpoint.searchParams.set("key", process.env.IP_API_KEY);

    let xhr = new XMLHttpRequest();
    // xhr.withCredentials = false;
    // xhr.setRequestHeader('X-RapidAPI-Key', 'SIGN-UP-FOR-KEY');
    // xhr.setRequestHeader('X-RapidAPI-Host', 'air-quality.p.rapidapi.com');

    xhr.open("GET", endpoint);
    xhr.responseType = "json";
    xhr.send();

    xhr.onload = function() {
      if (xhr.status != 200) {
        // analyze HTTP status of the response
        console.error(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        GeoLocationFailed();
      } else {
        // show the result
        console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response
        GeoLocationDetected(xhr.response);
      }
    };

    xhr.onerror = function() {
      console.error("XHR Request failed: " + xhr.status);
      GeoLocationFailed();
    };

    function GeoLocationDetected(response) {
      console.log(response);
      $.getJSON("/data/pm.json", function(data) {
        var pathname = window.location.pathname;
        var cityClearPath = pathname.replace(/[^\w\s]/gi, "");
        var cityIPmine = response.regionName;
        var cityIP = trchar(cityIPmine);
        if (pathname === "/diger/") {
          citySelectBox2();
          window.history.pushState({}, document.title, "/" + allParameters);
          $(".hero__description--no-city")
            .addClass("add")
            .removeClass("remove");
          $(".hero__description--have-city").addClass("remove");
          $(".bar").addClass("remove");
          $(".select-city")
            .val("Diğer")
            .trigger("change");
          $(".cities").append(
            '<div class="currentImageJenerik"><img class="currentImage__img__jenerik" src="/sehirler/jenerik.jpg" alt="Şehir görseli"/></div>'
          );
          $(".cities")
            .children()
            .remove(".currentImage");
        } else if (pathname !== "/") {
          $("#citySelect")
            .val(cityClearPath)
            .change();
          citySelectBox(cityClearPath);
          $(".hero__description--no-city").addClass("remove");
        } else {
          if (data.hasOwnProperty(cityIP)) {
            $("#citySelect")
              .val(cityIP)
              .change();
            citySelectBox(cityIP);
            $(".hero__description--no-city").addClass("remove");
          } else {
            citySelectBox2();
            $(".bar").addClass("remove");
            $(".hero__description--have-city")
              .addClass("remove")
              .removeClass("remove");
            $(".select-city")
              .val("Diğer")
              .trigger("change");
            $(".cities").append(
              '<div class="currentImageJenerik"><img class="currentImage__img__jenerik" src="/sehirler/jenerik.jpg" alt="Şehir görseli"/></div>'
            );
            $(".cities")
              .children()
              .remove(".currentImage");
          }
        }
      });
    }

    function GeoLocationFailed() {
      var pathname = window.location.pathname;
      var cityClearPath = pathname.replace(/[^\w\s]/gi, "");
      if (pathname === "/diger/" || pathname === "/") {
        citySelectBox2();
        window.history.pushState({}, document.title, "/" + allParameters);
        $(".hero__description--no-city").addClass("add");
        $(".hero__description--no-city").removeClass("remove");
        $(".hero__description--have-city").addClass("remove");
        $(".bar").addClass("remove");
        $(".select-city").val("Diğer");
        $(".select-city").trigger("change");
        $(".cities").append(
          '<div class="currentImageJenerik"><img class="currentImage__img__jenerik" src="/sehirler/jenerik.jpg" alt="Şehir görseli"/></div>'
        );
        $(".cities")
          .children()
          .remove(".currentImage");
      } else if (pathname !== "/") {
        $("#citySelect")
          .val(cityClearPath)
          .change();
        citySelectBox(cityClearPath);
        $(".hero__description--no-city").addClass("remove");
      }
    }

    // const endpoint = `${process.env.IP_API_BASE}?fields=status,message,regionName&key=${process.env.IP_API_KEY}`;
    // const endpoint = `${process.env.IP_API_BASE}`;
    // const data = null;

    // var xhr = new XMLHttpRequest();
    // xhr.onreadystatechange = function() {
    //   if (this.readyState === 4 && this.status === 200) {
    //     var response = JSON.parse(this.responseText);

    //     $.getJSON("/data/pm.json", function(data) {
    //       var pathname = window.location.pathname;
    //       var cityClearPath = pathname.replace(/[^\w\s]/gi, "");
    //       var cityIPmine = response.regionName;
    //       var cityIP = trchar(cityIPmine);
    //       if (pathname === "/diger/") {
    //         citySelectBox2();
    //         window.history.pushState({}, document.title, "/" + allParameters);
    //         $(".hero__description--no-city").addClass("add");
    //         $(".hero__description--no-city").removeClass("remove");
    //         $(".hero__description--have-city").addClass("remove");
    //         $(".bar").addClass("remove");
    //         $(".select-city").val("Diğer");
    //         $(".select-city").trigger("change");
    //         $(".cities").append(
    //           '<div class="currentImageJenerik"><img class="currentImage__img__jenerik" src="/sehirler/jenerik.jpg" alt="Şehir görseli"/></div>'
    //         );
    //         $(".cities")
    //           .children()
    //           .remove(".currentImage");
    //       } else if (pathname !== "/") {
    //         $("#citySelect")
    //           .val(cityClearPath)
    //           .change();
    //         citySelectBox(cityClearPath);
    //         $(".hero__description--no-city").addClass("remove");
    //       } else {
    //         if (data.hasOwnProperty(cityIP)) {
    //           $("#citySelect")
    //             .val(cityIP)
    //             .change();
    //           citySelectBox(cityIP);
    //           $(".hero__description--no-city").addClass("remove");
    //         } else {
    //           citySelectBox2();
    //           $(".bar").addClass("remove");
    //           $(".hero__description--have-city").addClass("remove");
    //           $(".hero__description--no-city").removeClass("remove");
    //           $(".select-city").val("Diğer");
    //           $(".select-city").trigger("change");
    //           $(".cities").append(
    //             '<div class="currentImageJenerik"><img class="currentImage__img__jenerik" src="/sehirler/jenerik.jpg" alt="Şehir görseli"/></div>'
    //           );
    //           $(".cities")
    //             .children()
    //             .remove(".currentImage");
    //         }
    //       }
    //     });
    //   }
    // };
    // xhr.open("GET", endpoint, true);
    // xhr.send();

    // $.get(endpoint)
    //   .done(function() {})
    //   .fail(function() {});

    // Select Box Function
    function citySelectBox(citySelectData) {
      $("#citySelect")
        .change(function() {
          if ($(this).val() !== "diger") {
            var citySelectData = $(this).val();

            // Anasayfadaki sehir gorseli degisiyor.
            if (
              $("#citySelect").val() === "cankiri" ||
              $("#citySelect").val() === "bingol" ||
              $("#citySelect").val() === "duzce" ||
              $("#citySelect").val() === "kahramanmaras" ||
              $("#citySelect").val() === "kilis" ||
              $("#citySelect").val() === "kirikkale" ||
              $("#citySelect").val() === "siirt" ||
              $("#citySelect").val() === "yozgat"
            ) {
              $(".cities").append(
                "<div class='nextImage'>" +
                  "<img class='nextImage__img' src='/sehirler/jenerik.jpg'></div>"
              );
            } else {
              $(".cities").append(
                "<div class='nextImage'>" +
                  "<img class='nextImage__img' src='/sehirler/" +
                  citySelectData +
                  ".jpg'></div>"
              );
            }

            $(".nextImage__img").on("load", function() {
              setTimeout(function() {
                $(".nextImage").addClass("show");
              }, 100);

              setTimeout(function() {
                $(".currentImage").remove();
                $(".nextImage").attr("class", "currentImage");
                $(".nextImage__img").attr("class", "currentImage__img");
              }, 600);
            });

            // form'daki sehir select'i degisiyor
            var citySelectValue = $(this)
              .find(":selected")
              .data("city");
            var citySelectFormValue = $(".select-city").val();
            if (citySelectValue !== citySelectFormValue) {
              $(".select-city")
                .val(citySelectValue)
                .change();
            }

            $("#hero").removeClass();
            $("#hero").toggleClass(citySelectData);
            $.getJSON("/data/pm.json", function(data) {
              $(".bar__container").data(
                "progress-percent",
                data[citySelectData].pm
              );
              $(".bar__arrow > span > strong").text(data[citySelectData].pm);
              $(".bar__arrow > span > strong").animate(
                { Counter: data[citySelectData].pm },
                {
                  duration: 500,
                  easing: "linear",
                  step: function(now) {
                    $(this).text(
                      Math.ceil(now)
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
                    );
                  },
                }
              );
              moveProgressBar();
              cloudsChange(data[citySelectData].pm);
              window.history.pushState(
                {},
                document.title,
                "/" + citySelectData + allParameters
              );
              if (data[citySelectData].pm === "-") {
                $(".bar__text, .bar__container").addClass("bar--hide");
                $(".undetermine")
                  .delay(400)
                  .fadeIn();
              } else {
                $(".bar__text, .bar__container").removeClass("bar--hide");
                $(".undetermine").fadeOut("fast");
              }
              var sehir_pm_degeri = data[citySelectData].pm * 1.5;
              $(".cities img").css({
                filter: "grayscale(" + sehir_pm_degeri + "%)",
                "-webkit-filter": "grayscale(" + sehir_pm_degeri + "%)",
                "-moz-filter": "grayscale(" + sehir_pm_degeri + "%)",
                "-o-filter": "grayscale(" + sehir_pm_degeri + "%)",
                "-ms-filter": "grayscale(" + sehir_pm_degeri + "%)",
              });
            });
          } else if (window.location.pathname === "/diger/") {
            window.history.pushState({}, document.title, "/" + allParameters);
          } else if ($(this).val() === "diger") {
            window.history.pushState(
              {},
              document.title,
              "/diger/" + allParameters
            );
            window.location.reload(true);
          } else {
            citySelectBox2();
            window.history.pushState(
              {},
              document.title,
              "/" + citySelectData + allParameters
            );
          }
        })
        .change();
    }
    function citySelectBox2(citySelectData) {
      $("#citySelect2")
        .change(function() {
          var citySelectData = $(this).val();
          if (citySelectData !== "noCity") {
            window.history.pushState(
              {},
              document.title,
              "/" + citySelectData + allParameters
            );
            window.location.reload(true);
          }
        })
        .change();
    }
    // Türkçe karakterleri ingilizceye
    // çevirmek için oluşturulan fonksiyon
    function trchar(text) {
      var trMap = {
        çÇ: "c",
        ğĞ: "g",
        şŞ: "s",
        üÜ: "u",
        ıİ: "i",
        öÖ: "o",
        âÂ: "a",
      };
      for (var key in trMap) {
        text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
      }
      return text
        .replace(/[^-a-zA-Z0-9\s]+/gi, "")
        .replace(/\s/gi, "")
        .replace(/[-]+/gi, "")
        .toLowerCase();
    }
    //
    // Kirlilik barını hareket ettirmek için
    //
    $(window).resize(function() {
      moveProgressBar();
    });
    moveProgressBar();
    function moveProgressBar() {
      var getProgressWrapWidth = $(".bar__container").width();
      var maxUnit = $(".bar__bar > .red").text();
      var unitPx = getProgressWrapWidth / maxUnit;
      var getPercent = $(".bar__container").data("progress-percent");
      var progressTotal = getPercent * unitPx;
      $(".bar__arrow").css({ marginLeft: progressTotal });
    }

    // PM degerine gore bulut animasyonu gorsel degisimi
    var cloudsChange = function(pmValue) {
      var cloudName = "clouds_level_01",
        currentCloudID = $(".currentCloud").attr("id");

      if (pmValue < 12 || pmValue === "") {
        cloudName = "clouds_level_01";
      } else if (pmValue < 36) {
        cloudName = "clouds_level_02";
      } else if (pmValue < 56) {
        cloudName = "clouds_level_03";
      } else {
        cloudName = "clouds_level_04";
      }

      if (cloudName !== currentCloudID) {
        $(".clouds").append(
          "<div id='" +
            cloudName +
            "' class='nextCloud'><img class='nextCloud__img' src='/images/clouds/" +
            cloudName +
            ".png' alt='kirlilik bulutu'></div>"
        );

        $(".nextCloud__img").on("load", function() {
          setTimeout(function() {
            $(".currentCloud").addClass("hide");
            $(".nextCloud").addClass("show");
          }, 100);

          setTimeout(function() {
            $(".currentCloud").remove();
            $(".nextCloud").attr("class", "currentCloud");
            $(".nextCloud__img").attr("class", "currentCloud__img");
          }, 600);
        });
      }
    };
    // Cookie controlu yapiliyor.
    // Eger cookie varsa form gizleniyor ve
    // supporter alani kullani ismiyle birlikte gosteriliyor, button linkine tesekkurler sayfasi ekleniyor
    var checkCookies = () => {
      var uName = cookies.get("uName"),
        uCity = cookies.get("uCity");

      if (uName && uCity) {
        $(".form-container").hide();
        $(".supporter").show();
        $(".signed").html("DESTEK VER");
        $("#sig-bar a").html("DESTEK VER");
        $(".supporter__name").text(" " + uName);
        $(".supporter__url").attr("href", "/" + uCity + "/tesekkurler");
      }
    };
    // cookies fonksiyonu cagiriliyor
    checkCookies();

    function commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    $.getJSON(
      `${process.env.API_URL}/api/total${process.env.API_APPEND}`,
      function(users) {
        if (users.success === true) {
          for (var i = 0; i < 5; i++) {
            var output;
            var cityApi = users.data.users[i].city;
            output =
              "<li><img src='/images/user.svg' class='user' alt='Son İmzalayanlar' />" +
              users.data.users[i].name +
              " " +
              users.data.users[i].surname +
              " <span class='city'><img src='/images/location.svg' class='location' alt='Son İmzalayanlar' />" +
              cityApi +
              "</span><span class='ago'>" +
              users.data.users[i].created_at +
              " imzaladı<span></li>";
            $(".last-signed").append(output);
          }
          var na = users.data.total;
          //
          // Numarayı yukarı saydır
          //
          $("#num, #num2").attr("data-counter", na);
          $("#num, #num2").each(function() {
            if (na > 15) {
              // Eğer total değer 15'den büyük
              // ise -15 değeriyle yukarı saydır
              $(this)
                .prop("Counter", 0)
                .animate(
                  {
                    Counter: na - 15,
                  },
                  {
                    duration: 3000,
                    easing: "swing",
                    step: function(now) {
                      $(this).text(
                        Math.ceil(now)
                          .toString()
                          .split(/(?=(?:\d{3})+(?:\.|$))/g)
                          .join(".")
                      );
                    },
                  }
                );
            } else {
              // Eğer gelen sayı 15'den küçük ise
              // orijinal total değeri yukarı saydır
              $(this)
                .prop("Counter", 0)
                .animate(
                  {
                    Counter: na - 15,
                  },
                  {
                    duration: 3000,
                    easing: "swing",
                    step: function(now) {
                      $(this).text(
                        Math.ceil(now)
                          .toString()
                          .split(/(?=(?:\d{3})+(?:\.|$))/g)
                          .join(".")
                      );
                    },
                  }
                );
            }
          });

          function countFake() {
            var getNum = $("#num, #num2").attr("data-counter");
            var newNum = getNum - 15;
            var num = parseInt(newNum);
            var max = 15;
            var numMax = num + max;
            var counter = 1;
            var toplam = 0;
            var random1, random2, random3, random4, random5;
            do {
              random1 = Math.floor(Math.random() * 4) + 2;
              random2 = Math.floor(Math.random() * 4) + 2;
              random3 = Math.floor(Math.random() * 4) + 2;
              random4 = Math.floor(Math.random() * 4) + 2;
              toplam = random1 + random2 + random3 + random4;
            } while (toplam >= 15);
            random5 = 15 - toplam;
            toplam = toplam + random5;
            function ascara() {
              counter++;
              if (counter === 2) {
                num = num + random1;
                document.getElementById("num").innerHTML = commas(num);
                document.getElementById("num2").innerHTML = commas(num);
              }
              if (counter === 3) {
                num = num + random2;
                document.getElementById("num").innerHTML = commas(num);
                document.getElementById("num2").innerHTML = commas(num);
              }
              if (counter === 4) {
                num = num + random3;
                document.getElementById("num").innerHTML = commas(num);
                document.getElementById("num2").innerHTML = commas(num);
              }
              if (counter === 5) {
                num = num + random4;
                document.getElementById("num").innerHTML = commas(num);
                document.getElementById("num2").innerHTML = commas(num);
              }
              if (counter === 6) {
                num = num + random5;
                document.getElementById("num").innerHTML = commas(num);
                document.getElementById("num2").innerHTML = commas(num);
              }
              return;
            }
            function lastSigned() {
              $(".last-signed li:first").slideUp(function() {
                $(this)
                  .appendTo($(".last-signed"))
                  .slideDown();
              });
            }
            (function loop() {
              if (num < numMax) {
                var rand = Math.round(Math.random() * (5000 - 1000)) + 2000;
                setTimeout(function() {
                  lastSigned();
                  if (na > max) {
                    ascara();
                  }
                  loop();
                }, rand);
              }
            })();
          }
          //
          // Numarayı yukarı saydırma fonksiyonunun bitmesini bekleyip
          // Daha sonra loop ve fake counter işlemini başlatıyor.
          //
          setTimeout(countFake, 3100);
        }
      }
    );

    // Mobil mi Desktop mı?
    var isMobile, isDesktop;

    $(window).on("resize", function() {
      if ($(window).width() > 639) {
        isMobile = false;
        isDesktop = true;
      } else {
        isMobile = true;
        isDesktop = false;
      }
      // console.log("isMobile:" + isMobile + " // isDesktop: " + isDesktop);
    });
    $(window).resize(); // Başlangıçta çalıştır.

    // E-posta alanına tıklayınca DIV'i büyüt
    var isFormOpen = false;
    $("#eposta").focus(function() {
      $(".focus-in").slideDown("fast");
      isFormOpen = true;
      // İmza ver butonunu sabitle
      $(".send").addClass("send--relative");
      // Analytics - Etkişelim başladı
      if (typeof window.gtag === "function") {
        window.gtag("event", "Form Started", {
          event_category: "engagement",
          event_label: "Started",
        });
      }
    });

    // Scroll ile tetiklenen eylemler onScroll fonksiyonu içinde birleştirilecek.
    $(document.body).on("touchmove", onScroll); // for mobile
    $(window).on("scroll", onScroll);

    function onScroll() {
      // Mobile-Only - Sticky İmza Ver Butonu
      if (!isFormOpen && isMobile) {
        var hT = $(".form-container").offset().top,
          hH = $(".form-container").outerHeight() + 16,
          targY = hT + hH,
          wH = window.innerHeight, //$(window).height(),
          wS = $(this).scrollTop(),
          scrollTarg = targY - wH;
        if (wS >= scrollTarg) {
          $(".send").addClass("send--relative");
        } else {
          $(".send").removeClass("send--relative");
        }
      }

      // PM 2.5 Nedir? alanına gelince imza ver bannerını sabitle
      //if(isDesktop) {
      //  var a = $("#pm").offset();
      //if ($(window).scrollTop() > a.top)
      //  $("#sig-bar").css({
      //    bottom: "0",
      //  });
      //else
      //  $("#sig-bar").css({
      //    bottom: "-132px",
      //  });
      //}
    }
  }
  render() {
    return (
      // <Layout>
      //   <Header></Header>
      //   <main>
      //     <Hero></Hero>
      //   </main>
      //   <Footer hclass="footer--home"></Footer>
      //   <Loader></Loader>
      // </Layout>

      <Layout>
        <Header></Header>
        <main>
          <Hero></Hero>
          <Pm></Pm>
          <TwoOne></TwoOne>
          <Breath></Breath>
          <TwoTwo></TwoTwo>
          <TwoThree></TwoThree>
          <Citys></Citys>
          <Numbers></Numbers>
          <Bar></Bar>
        </main>
        <Footer></Footer>
        <Loader></Loader>
      </Layout>
    );
  }
}

export default Home;
