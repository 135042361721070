import React from "react";
import $ from "jquery";

import "./index.scss";

class Loader extends React.Component {
  componentDidMount() {
    $(document).ready(function() {
      $("#loader").fadeOut("slow");
    });
  }
  render() {
    return (
      <div id="loader">
        <div id="spin"></div>
      </div>
    );
  }
}
export default Loader;
