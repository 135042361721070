import React from "react";
import LazyLoad from "react-lazyload";

const Twothree = () => (
  <section
    id="two-col-section"
    className="two-col-section two-col-section--jigsaw"
  >
    <div className="row">
      <div className="col-md-6 col-xs-12 two-col-section__left">
        <LazyLoad offset={200}>
          <img
            className="two-col-section--limit__jigsaw"
            src="/images/jigsaw.jpg"
            alt="Yetersiz ölçüm sorunu son bulsun!"
            srcSet="/images/m_jigsaw.jpg 1000w, /images/jigsaw.jpg 1001w"
            sizes="(min-width: 1000px),100vw"
          ></img>
        </LazyLoad>
      </div>
      <div className="col-md-6 col-xs-12 two-col-section__right">
        <h2>
          Yetersiz ölçüm sorunu
          <br />
          son bulsun!
        </h2>
        <p>
          <strong>
            Türkiye’de hava kalitesi ölçümleri Çevre ve Şehircilik Bakanlığı
            tarafından Hava Kalitesi İzleme İstasyonları’ndan yapılıyor.
          </strong>{" "}
          Sayıları toplam 330 olduğu bildirilen istasyonların sadece 211’inin
          (%64) verileri kamuoyu ile paylaşılıyor. 2018 yılında bunların
          163’ünde, yani toplam istasyon sayısı olan 330’un yarısından çoğunda
          yeterli sayıda ölçüm yapılmamış veya ölçüm verileri bildirilmemiş. Bir
          başka örnek ise İstanbul. İstanbul’da 2018 yılında toplam 30
          istasyonun sadece %30’unda yeterli ölçüm yapılmış.
          <br />
          Bu durum devam etmemeli. Temiz hava hakkımızı savunmalı ve hava
          kirliliği konusunda veri güvenliğinin geliştirilmesini talep
          etmeliyiz.
        </p>
        <a href="#form" className="signed">
          İMZA VER
        </a>
      </div>
    </div>
  </section>
);

export default Twothree;
