import React from "react";
import LazyLoad from "react-lazyload";

import "./index.scss";

const Citys = () => (
  <section id="citys" className="citys">
    <div className="row container">
      <div className="col-md-6 col-xs-12 citys__left">
        <div className="row">
          <h2 className="col-xs-12">
            Havası En <strong>Temiz</strong> 3 Şehir{" "}
          </h2>
          <div className="col-xs-4">
            <LazyLoad offset={200} height={273}>
              <div className="citys__item">
                <img
                  className="citys__item__img lazyload"
                  alt="Ardahan"
                  src="/images/ardahan.jpg"
                />
                <div className="citys__item__text">
                  <div className="citys__item__text__numbers">
                    <span>12</span>
                    <div className="citys__item__text__numbers__dot">
                      <div className="dot"></div>µg/m³
                    </div>
                  </div>
                  <h3>Ardahan</h3>
                </div>
              </div>
            </LazyLoad>
          </div>
          <div className="col-xs-4">
            <LazyLoad offset={200} height={273}>
              <div className="citys__item">
                <img
                  className="citys__item__img lazyload"
                  alt="Rize"
                  src="/images/rize.jpg"
                />
                <div className="citys__item__text">
                  <div className="citys__item__text__numbers">
                    <span>13</span>
                    <div className="citys__item__text__numbers__dot">
                      <div className="dot"></div>µg/m³
                    </div>
                  </div>
                  <h3>Rize</h3>
                </div>
              </div>
            </LazyLoad>
          </div>
          <div className="col-xs-4">
            <LazyLoad offset={200} height={273}>
              <div className="citys__item">
                <img
                  className="citys__item__img lazyload"
                  alt="Hakkari"
                  src="/images/hakkari.jpg"
                />
                <div className="citys__item__text">
                  <div className="citys__item__text__numbers">
                    <span>14</span>
                    <div className="citys__item__text__numbers__dot">
                      <div className="dot"></div>µg/m³
                    </div>
                  </div>
                  <h3>Hakkari</h3>
                </div>
              </div>
            </LazyLoad>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xs-12 citys__right">
        <div className="row">
          <h2 className="col-xs-12">
            Havası En <strong>Kirli</strong> 3 Şehir{" "}
          </h2>
          <div className="col-xs-4">
            <LazyLoad offset={200} height={273}>
              <div className="citys__item">
                <img
                  className="citys__item__img lazyload"
                  alt="Mersin"
                  src="/images/mersin.jpg"
                />
                <div className="citys__item__text">
                  <div className="citys__item__text__numbers">
                    <span>56</span>
                    <div className="citys__item__text__numbers__dot">
                      <div className="dot"></div>µg/m³
                    </div>
                  </div>
                  <h3>Mersin</h3>
                </div>
              </div>
            </LazyLoad>
          </div>
          <div className="col-xs-4">
            <LazyLoad offset={200} height={273}>
              <div className="citys__item">
                <img
                  className="citys__item__img lazyload"
                  alt="Kahramanmaraş"
                  src="/images/kahramanmaras.jpg"
                />
                <div className="citys__item__text">
                  <div className="citys__item__text__numbers">
                    <span>61</span>
                    <div className="citys__item__text__numbers__dot">
                      <div className="dot"></div>µg/m³
                    </div>
                  </div>
                  <h3>K.Maraş</h3>
                </div>
              </div>
            </LazyLoad>
          </div>
          <div className="col-xs-4">
            <LazyLoad offset={200} height={273}>
              <div className="citys__item">
                <img
                  className="citys__item__img lazyload"
                  alt="Igdir"
                  src="/images/igdir.jpg"
                />
                <div className="citys__item__text">
                  <div className="citys__item__text__numbers">
                    <span>65</span>
                    <div className="citys__item__text__numbers__dot">
                      <div className="dot"></div>µg/m³
                    </div>
                  </div>
                  <h3>Iğdır</h3>
                </div>
              </div>
            </LazyLoad>
          </div>
        </div>
      </div>
      <p className="col-xs-12 citys__not">
        Veriler 2018, PM 10 ölçümlerine göre derlenmiştir.
      </p>
    </div>
    <LazyLoad offset={200}>
      <img
        className="cities__img lazyload"
        src="/images/cities.jpg"
        alt="Havası en temiz ve en kirli şehirler"
        srcSet="/images/m_cities.jpg 1000w, /images/cities.jpg 1001w"
        sizes="(min-width: 1000px),100vw"
      ></img>
    </LazyLoad>
  </section>
);

export default Citys;
