import React from "react";
import LazyLoad from "react-lazyload";

import "./index.scss";

const Numbers = () => (
  <section className="number-air" id="number-air">
    <div className="container row">
      <h2 className="col-xs-12">Sayılarla hava kirliliği</h2>
      <div className="col-md-4 number-air__item">
        <h3 className="number-air__title">
          <span>%</span>56
        </h3>
        <p>
          Son üç yılda <strong>ulusal sınır değerlere göre</strong>
          <br />
          81 ilin yarısından fazlası
          <br />
          kirli hava soludu.
        </p>
      </div>
      <div className="col-md-4 number-air__item">
        <h3 className="number-air__title">
          <span>%</span>13
        </h3>
        <p>
          2017 yılında Türkiye’de hava kirliliği Dünya Sağlık Örgütü tarafından
          önerilen seviyelere indirilseydi ölümlerin %13’ü önlenebilirdi.
        </p>
      </div>
      <div className="col-md-4 number-air__item">
        <h3 className="number-air__title">
          <span>%</span>93
        </h3>
        <p>
          Türkiye’de 2018 yılında yeterli ölçüm yapılan 163 istasyonun
          %96,3’ünde yıllık PM 10 ortalaması{" "}
          <abbr title="Dünya Sağlık Örgütü">DSÖ</abbr> limitlerinin üzerinde
          kirli.
        </p>
      </div>
      <div className="col-md-6 number-air__item">
        <h3 className="number-air__title">7 kat</h3>
        <p>
          Türkiye’de 2017 yılında hava kirliliği trafik kazalarından
          <br />7 kat fazla can aldı.
        </p>
      </div>
      <div className="col-md-6 number-air__item">
        <h3 className="number-air__title">İlk 3 il</h3>
        <p>
          2017 yılında hava kirliliği nedeniyle en fazla ölümün yaşandığı ilk üç
          il <strong>İstanbul</strong> (5.851), <strong>Bursa</strong> (3.098)
          ve <strong>Ankara</strong> (2.139) oldu.
        </p>
      </div>
      <span className="col-xs-12 number-air__not">
        Veriler, Temiz Hava Hakkı Platformu, Kara Rapor, 2019’dan derlenmiştir.
      </span>
    </div>
    <LazyLoad offset={200}>
      <img
        className="number-air__img"
        alt="Sağlığımız Havada Kalmasın"
        src="/images/sayilarla-hava.jpg"
      />
    </LazyLoad>
  </section>
);

export default Numbers;
