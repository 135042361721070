import React from "react";
import LazyLoad from "react-lazyload";

import "./index.scss";

const TwoOne = () => (
  <section
    id="two-col-section"
    className="two-col-section two-col-section--flue"
  >
    <div className="row">
      <div className="col-md-6 col-xs-12 two-col-section__left">
        <LazyLoad offset={200}>
          <picture className="two-col-section--limit__flue">
            <source
              media="(max-width: 638px)"
              srcSet="/images/m_flue.jpg"
            ></source>
            <img
              src="/images/flue.jpg"
              alt="Sağlığımız Havada Kalmasın"
            ></img>
          </picture>
        </LazyLoad>
      </div>
      <div className="col-md-6 col-xs-12 two-col-section__right">
        <h2>
          Sağlığımız
          <br />
          Havada Kalmasın
        </h2>
        <p>
          <strong>
            Hava kirliliği dünyada her yıl sekiz milyon erken ölüme yol açıyor.
          </strong>{" "}
          Hava kirliliğinin solunum, kalp, damar ve nörolojik sistem başta olmak
          üzere sağlık etkileri çok geniş. Kanser, düşük doğum ağırlıkları,
          diyabet, metabolik sendrom, gelişme bozuklukları, doğum bozuklukları,
          hava kirliliğinin bağlantısı artık biliniyor. Hava kirliliği, anne
          karnındaki bebeği bile etkiliyor! Dünya Sağlık Örgütü hava kirliliği
          için “tütünden bile zararlı!” açıklaması yaparken Uluslararası Kanser
          Ajansı partikül madde kirliliğini “kanserojen” olarak sınıflandırıyor.
        </p>
        <a href="#form" className="signed">
          İMZA VER
        </a>
      </div>
    </div>
  </section>
);

export default TwoOne;
