import React from "react";
import LazyLoad from "react-lazyload";

import "./index.scss";

const Breath = () => (
  <section id="breath" className="breath">
    <div className="container row">
      <h2 className="col-xs-12">
        Nefes Almak Hayattır; <strong>Öldürmesin!</strong>
      </h2>
      <p className="col-xs-12">
        <strong>
          2017 yılında Türkiye’de hava kirliliği, trafik kazalarının 7 katı
          kadar can aldı.
        </strong>{" "}
        O yılda Türkiye’de hava kirliliği Dünya Sağlık Örgütü tarafından
        önerilen seviyelere indirilseydi ölümlerin %13’ü önlenebilirdi. Dünyanın
        pek çok ülkesinde belirlenmiş olan PM 2.5 ulusal sınır değerleri
        Türkiye’de de belirlenmeli. Halk sağlığının gözetilmesi ve sağlık
        maliyetlerinin düşürülmesi için bu çok önemli bir adım olacak.{" "}
      </p>
      <span className="col-xs-12">
        <a href="#form" className="signed">
          İMZA VER
        </a>
      </span>
    </div>
    <LazyLoad offset={200}>
      <img
        className="two-col-section--limit__flue"
        src="/images/breath.jpg"
        alt="Nefes Almak Hayattır; Öldürmesin!"
        srcSet="/images/m_breath.jpg 1000w, /images/breath.jpg 1001w"
        sizes="(min-width: 1000px),100vw"
      ></img>
    </LazyLoad>
  </section>
);

export default Breath;
