import React from "react";

import "./index.scss";

const Bar = () => (
  <section id="sig-bar" className="sig-bar">
    <div className="container row middle-xs">
      <h6 className="col-md-10 col-xs-12">
        Sessiz katili durdurmak için <strong>kampanyaya katıl!</strong>
      </h6>
      <div className="col-md-2 col-xs-12">
        <a href="#form">İMZA VER</a>
      </div>
    </div>
  </section>
);

export default Bar;
