import React from "react";
import $ from "jquery";
import jQuery from "jquery";
import "jquery-validation";
import InputMask from "react-input-mask";
import Cookies from "universal-cookie";

import "./index.scss";

const cookies = new Cookies();

class Form extends React.Component {
  componentDidMount() {
    // input icerisinde veri olup olmadigina bakiliyor. Eger veri varsa, label yukarida bekliyor.
    $(".focus:not(select)").blur(function() {
      var a = $(this).val();
      if (0 === a.length)
        $(this)
          .parents(".form-field")
          .removeClass("focused");
      else
        $(this)
          .parents(".form-field")
          .addClass("focused");
    });

    $("#eposta, #isim, #soyisim, #phone").each(function() {
      var inputValue = $(this).val();
      if (inputValue.length === 0) {
        $(this)
          .parents(".form-field")
          .removeClass("focused");
      } else {
        $(this)
          .parents(".form-field")
          .addClass("focused");
      }
    });

    // city selectbox'inda veri olup olmadigina bakiliyor. Eger veri varsa, label yukarida bekliyor.
    var citySelected = $("#form-city")
      .find("option:selected")
      .val();
    if (citySelected !== "noCity") {
      $("#form-city")
        .parents(".city-select-field")
        .addClass("focused");
    }

    // birth-year selectbox'inda veri olup olmadigina bakiliyor. Eger veri varsa, label yukarida bekliyor.
    var birthYearSelected = $("#birth-year")
      .find("option:eq(0)")
      .prop("selected", true)
      .find("option:selected")
      .val();
    if (birthYearSelected) {
      $("#birth-year")
        .parents(".city-select-field")
        .addClass("focused");
    }

    $("#form-city, #birth-year").change(function() {
      if (
        "noCity" ===
        $(this)
          .find(":selected")
          .data("city")
      )
        $(this)
          .parents(".city-select-field")
          .removeClass("req_message");
      else {
        $(this)
          .parents(".city-select-field")
          .children("#club-select-error")
          .remove();
        $(this)
          .parents(".city-select-field")
          .addClass("focused");
        $(this).removeClass("req_message");
        $(this)
          .parents(".city-select-field")
          .removeClass("req_message");
        $(this)
          .parents(".city-select-field")
          .children(".form-label")
          .removeClass("req_message");
      }

      // form city degistiginde degerler ayni degilse #citySelect de degisiyor
      if (
        $(".select-city")
          .find(":selected")
          .data("city") !== $("#citySelect").val() &&
        $(".select-city")
          .find(":selected")
          .data("city") !== "diger"
      ) {
        $("#citySelect").val(
          $(".select-city")
            .find(":selected")
            .data("city")
        );
        $("#citySelect").trigger("change");
      }
    });

    // Form validation
    // Özel karakter engelleme
    jQuery.validator.addMethod(
      "alphanumeric",
      function(a, b) {
        return this.optional(b) || /^[A-Z a-z-0-9\u00C0-\u017F]+$/.test(a);
      },
      "Lütfen özel karakter kullanmayın."
    );
    // Sayı engelleme
    jQuery.validator.addMethod(
      "nonNumericSpecial",
      function(a, b) {
        return this.optional(b) || !a.match(/[0-9]+/);
      },
      "Lütfen rakam kullanmayın."
    );

    $.validator.addMethod(
      "regex",
      function(value, element, regexp) {
        //var check = false;
        var re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
      },
      "Lütfen özel karakter kullanmayın."
    );

    $.validator.setDefaults({
      ignore: [],
    });
    setTimeout(function() {
      $("#eposta-error, #name-error, #surname-error").fadeOut();
    }, 5000);

    $(document).on("mouseenter", ".on-error, span.req_message", function(a) {
      $(".on-error, span.req_message").fadeOut(1000);
    });

    //
    // Form validation and submit
    //
    // Eğer E-posta alanını doldurmadıysa validation çalışmayacak
    // ve buton fonksiyonu #eposta input'una focus olacak

    // Get URL Parameters
    function GetURLParameter(e) {
      for (
        var s = window.location.search.substring(1), o = s.split("&"), n = 0;
        n < o.length;
        n++
      ) {
        var r = o[n].split("=");
        if (r[0] === e) return r[1];
      }
    }
    var phone = GetURLParameter("p");
    if (phone === "true") {
      $("#phone").prop("required", true);
      $("#phone-field label").html("Cep Telefonu *");
      var phoneRules = {
        required: true,
        minlength: 13,
        regex: /^\d{2}(?: ?\d+)*$/,
      };
      var phoneMessage = {
        minlength: jQuery.validator.format(
          "Lütfen geçerli bir telefon numarası girin."
        ),
        required: "Telefon alanını boş bırakamazsınız.",
        regex: "Lütfen geçerli bir telefon numarası girin.",
      };
    } else {
      phoneRules = {
        required: false,
        minlength: 13,
        regex: /^\d{2}(?: ?\d+)*$/,
      };
      phoneMessage = {
        minlength: jQuery.validator.format(
          "Lütfen geçerli bir telefon numarası girin."
        ),
        required: "Telefon alanını boş bırakamazsınız.",
        regex:
          "Lütfen geçerli bir telefon numarası girin veya bu alanı boş bırakın.",
      };
    }
    $("#eposta").blur(function() {
      $(".form").validate({
        errorClass: "req_message",
        errorElement: "span",
        highlight: function(a, b, c) {
          $(a)
            .parent()
            .removeClass(c)
            .addClass(b);
          $(a)
            .addClass(b)
            .removeClass(c);
          $(a.form)
            .find("label[for=" + a.id + "]")
            .addClass(b);
        },
        unhighlight: function(a, b, c) {
          $(a)
            .removeClass(b)
            .addClass(c);
          $(a)
            .parent()
            .addClass(b)
            .removeClass(c);
          $(a.form)
            .find("label[for=" + a.id + "]")
            .removeClass(b);
        },
        rules: {
          name: {
            required: true,
            alphanumeric: true,
            nonNumericSpecial: true,
            minlength: 2,
            maxlength: 15,
          },
          surname: {
            required: true,
            alphanumeric: true,
            nonNumericSpecial: true,
            minlength: 2,
            maxlength: 15,
          },
          email: {
            required: true,
            email: true,
            minlength: 6,
          },
          city: {
            required: false,
          },
          phone: phoneRules,
          birthyear: {
            required: false,
          },
          email_permission: {
            required: false,
          },
          call_permission: {
            required: false,
          },
          sms_permission: {
            required: false,
          },
        },
        messages: {
          name: {
            minlength: jQuery.validator.format(
              "Lütfen gerçek isminizi giriniz."
            ),
            maxlength: jQuery.validator.format(
              "İsminiz en fazla {0} karakter içermelidir."
            ),
            required: "İsim alanını boş bırakamazsınız.",
          },
          surname: {
            minlength: jQuery.validator.format(
              "Lütfen gerçek soyisminizi giriniz."
            ),
            maxlength: jQuery.validator.format(
              "Soyisminiz en fazla {0} karakter içermelidir."
            ),
            required: "Soyisim alanını boş bırakamazsınız.",
          },
          email: {
            minlength: "Lütfen gerçek email adresinizi giriniz.",
            required: "Lütfen geçerli bir e-posta adresi girin.",
            email: "Lütfen gerçek email adresinizi giriniz.",
          },
          phone: phoneMessage,
        },
        submitHandler: function(form) {
          // submit button inaktif hale getiriliyor.
          $(".btnSubmit").prop("disabled", true);

          // form verisi serialize ediliyor
          var data = $(".form").serialize();

          // Form sunucuya submit ediliyor
          var jqxhr = $.post(
            `${process.env.API_URL}/api/register${process.env.API_APPEND}`,
            data
          )
            .done(function(result) {
              // Sehir secimine gore yonlenilecek tesekkurler sayfasi belirleniyor.
              var uName = $("#name").val();
              var citySelectData = $(".select-city")
                .find(":selected")
                .data("city");
              var uCity = citySelectData;

              if (uCity === "noCity" || uCity === "diger") {
                uCity = "turkiye";
              }

              // Form basarili oldugu icin google analytics'e veri gonderiliyor
              if (typeof window.gtag === "function") {
                window.gtag("event", "Form Success", {
                  event_category: "engagement",
                  event_label: "Success",
                });
              }

              // Cookie ayarlaniyor ve yonlendirme yapiliyor
              cookies.set("uName", uName, { path: "/", maxAge: "604800" });
              cookies.set("uCity", uCity, { path: "/", maxAge: "604800" });
              window.location.replace(`/` + uCity + `/tesekkurler`);

              $(".btnSubmit").prop("disabled", false);
            })
            .fail(function(result) {
              // hata mesajlari ekranda gosteriliyor
              var errorsData = Object.values(result.responseJSON.errors);
              // varsa onceki hata mesajlari kaldiriliyor
              $(".req_response_api").remove();
              // hata mesajlari ekrana yazdiriliyor
              if (result.responseJSON.errors.email) {
                $("#eposta").after(
                  "<span id='eposta-error' class='req_message req_response_api'>" +
                    errorsData[0] +
                    "</span>"
                );
                $("#eposta").addClass("req_message");
                // Analytics Event
                if (typeof window.gtag === "function") {
                  window.gtag("event", "Email Error", {
                    event_category: "errors",
                    event_label: errorsData[0],
                  });
                }
                $("html, body").animate(
                  {
                    scrollTop: $("#form").offset().top,
                  },
                  500
                );
                $("#eposta").focus();
                setTimeout(function() {
                  $("#eposta-error, #name-error, #surname-error").fadeOut();
                }, 5e3);
              }
              if (result.responseJSON.errors.name) {
                $("#name").after(
                  "<span id='name-error' class='req_message req_response_api'>" +
                    errorsData[0] +
                    "</span>"
                );
                $("#name").addClass("req_message");
              }
              if (result.responseJSON.errors.surname) {
                $("#surname").after(
                  "<span id='surname-error' class='req_message req_response_api'>" +
                    errorsData[0] +
                    "</span>"
                );
                $("#surname").addClass("req_message");
              }

              $(".btnSubmit").prop("disabled", false);
            });

          return false;
        },
        invalidHandler: function(form) {
          var formerrorList = $(".form").data("validator").errorList;
          $.each(formerrorList, function(key, value) {
            var error_item =
              formerrorList[key].element.id.charAt(0).toUpperCase() +
              formerrorList[key].element.id.slice(1);
            // Google Analytics için hata form eventları
            if (typeof window.gtag === "function") {
              window.gtag("event", error_item + " Error", {
                event_category: "engagement",
                event_label: "Error",
              });
            }
          });
        },
      });
    });
    $(".send__for-mobile").click(function() {
      $("html, body").animate(
        {
          scrollTop: $(".form").offset().top,
        },
        500
      );
      $("#eposta").focus();
      $(this).remove();
    });
    // Eğer kullanıcı cache'de belirtilen kullanıcı
    // değilse ".supporter__reset" linkine tıkladığında
    // sayfa yenilenecek ve cache temizlenecek
    $(".supporter__reset").click(function() {
      cookies.remove("uName", { path: "/" });
      cookies.remove("uCity", { path: "/" });
      window.location.reload(true);
    });

    // Google Analytics Form Events
    var GA_email = document.querySelector("#eposta");
    var GA_name = document.querySelector("#name");
    var GA_surname = document.querySelector("#surname");
    var GA_city = document.querySelector("#form-city");
    var GA_phone = document.querySelector("#phone");
    var GA_birth = document.querySelector("#birth-year");
    var GA_email_per = document.querySelector("#eposta_check");
    var GA_sms_per = document.querySelector("#sms");
    var GA_phone_per = document.querySelector("#telefon_check");

    // Google Analytics form elemanları için fonksiyonlar
    ga_focus(GA_email, "Email");
    ga_focus(GA_name, "Name");
    ga_focus(GA_surname, "Surname");
    ga_focus(GA_phone, "Phone");
    ga_click(GA_email_per, "Email permission");
    ga_click(GA_sms_per, "SMS permission");
    ga_click(GA_phone_per, "Phone permission");
    ga_change(GA_city, "City");
    ga_change(GA_birth, "Birth year");

    // focus için fonksiyon
    function ga_focus(ga_var, ga_name) {
      ga_var.addEventListener(
        "focus",
        function(e) {
          if (typeof window.gtag === "function") {
            window.gtag("event", ga_name + " Focus", {
              event_category: "engagement",
              event_label: "Focus",
            });
          }
        },
        {
          once: true,
        }
      );
    }
    // change için fonksiyon
    function ga_change(ga_var, ga_name) {
      ga_var.addEventListener(
        "change",
        function(e) {
          if (typeof window.gtag === "function") {
            window.gtag("event", ga_name + " Change", {
              event_category: "engagement",
              event_label: "Change",
            });
          }
        },
        {
          once: true,
        }
      );
    }
    // click için fonksiyon
    function ga_click(ga_var, ga_name) {
      ga_var.addEventListener(
        "click",
        function(e) {
          if (typeof window.gtag === "function") {
            window.gtag("event", ga_name + " Click", {
              event_category: "engagement",
              event_label: "Click",
            });
          }
        },
        {
          once: true,
        }
      );
    }
  }

  render() {
    return (
      <div className="form-container" id="form">
        <form className="row form" autoComplete="off">
          <h2 className="form__title col-xs-12">
            Sen de <strong>"havada kalmasın"</strong> diyerek harekete geç, PM
            2.5 için AB ile uyumlu ulusal sınır değer belirlensin, derin bir
            nefes alalım.
          </h2>
          <input type="hidden" name="utm_source" id="utm_source" />
          <input type="hidden" name="utm_medium" id="utm_medium" />
          <input type="hidden" name="utm_campaign" id="utm_campaign" />
          <input type="hidden" name="utm_content" id="utm_content" />
          <input type="hidden" name="utm_term" id="utm_term" />
          <div className="col-xs-12 form-field">
            <input
              className="focus"
              type="email"
              id="eposta"
              name="email"
              autoComplete="off"
              placeholder="E-posta"
              required
            />
            <label className="form-label" htmlFor="eposta">
              E-posta *
            </label>
          </div>
          <div className="focus-in">
            <div className="row">
              <div className="col-xs-12 col-md-6 form-field left name">
                <input
                  className="focus"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="İsim"
                  pattern="^([ À-ǿa-zA-Z'-]{2,25})+$"
                  required
                />
                <label className="form-label" htmlFor="name">
                  İsim *
                </label>
              </div>
              <div className="col-xs-12 col-md-6 form-field right surname">
                <input
                  className="focus"
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder="Soyisim"
                  pattern="^([ À-ǿa-zA-Z'-]{2,25})+$"
                  required
                />
                <label className="form-label" htmlFor="surname">
                  Soyisim *
                </label>
              </div>
              <div className="col-xs-12 form-field city-select-field">
                <select id="form-city" name="city" className="select-city">
                  <option value="Diğer" data-city="diger" defaultValue>
                    Diğer
                  </option>
                  <option value="İstanbul" data-city="istanbul">
                    İstanbul
                  </option>
                  <option value="Ankara" data-city="ankara">
                    Ankara
                  </option>
                  <option value="İzmir" data-city="izmir">
                    İzmir
                  </option>
                  <option value="Adana" data-city="adana">
                    Adana
                  </option>
                  <option value="Adıyaman" data-city="adiyaman">
                    Adıyaman
                  </option>
                  <option value="Afyonkarahisar" data-city="afyonkarahisar">
                    Afyonkarahisar
                  </option>
                  <option value="Ağrı" data-city="agri">
                    Ağrı
                  </option>
                  <option value="Aksaray" data-city="aksaray">
                    Aksaray
                  </option>
                  <option value="Amasya" data-city="amasya">
                    Amasya
                  </option>
                  <option value="Antalya" data-city="antalya">
                    Antalya
                  </option>
                  <option value="Ardahan" data-city="ardahan">
                    Ardahan
                  </option>
                  <option value="Artvin" data-city="artvin">
                    Artvin
                  </option>
                  <option value="Aydın" data-city="aydin">
                    Aydın
                  </option>
                  <option value="Balıkesir" data-city="balikesir">
                    Balıkesir
                  </option>
                  <option value="Bartın" data-city="bartin">
                    Bartın
                  </option>
                  <option value="Batman" data-city="batman">
                    Batman
                  </option>
                  <option value="Bayburt" data-city="bayburt">
                    Bayburt
                  </option>
                  <option value="Bilecik" data-city="bilecik">
                    Bilecik
                  </option>
                  <option value="Bingöl" data-city="bingol">
                    Bingöl
                  </option>
                  <option value="Bitlis" data-city="bitlis">
                    Bitlis
                  </option>
                  <option value="Bolu" data-city="bolu">
                    Bolu
                  </option>
                  <option value="Burdur" data-city="burdur">
                    Burdur
                  </option>
                  <option value="Bursa" data-city="bursa">
                    Bursa
                  </option>
                  <option value="Çanakkale" data-city="canakkale">
                    Çanakkale
                  </option>
                  <option value="Çankırı" data-city="cankiri">
                    Çankırı
                  </option>
                  <option value="Çorum" data-city="corum">
                    Çorum
                  </option>
                  <option value="Denizli" data-city="denizli">
                    Denizli
                  </option>
                  <option value="Diyarbakır" data-city="diyarbakir">
                    Diyarbakır
                  </option>
                  <option value="Düzce" data-city="duzce">
                    Düzce
                  </option>
                  <option value="Edirne" data-city="edirne">
                    Edirne
                  </option>
                  <option value="Elazığ" data-city="elazig">
                    Elazığ
                  </option>
                  <option value="Erzincan" data-city="erzincan">
                    Erzincan
                  </option>
                  <option value="Erzurum" data-city="erzurum">
                    Erzurum
                  </option>
                  <option value="Eskişehir" data-city="eskisehir">
                    Eskişehir
                  </option>
                  <option value="Gaziantep" data-city="gaziantep">
                    Gaziantep
                  </option>
                  <option value="Giresun" data-city="giresun">
                    Giresun
                  </option>
                  <option value="Gümüşhane" data-city="gumushane">
                    Gümüşhane
                  </option>
                  <option value="Hakkâri" data-city="hakkari">
                    Hakkâri
                  </option>
                  <option value="Hatay" data-city="hatay">
                    Hatay
                  </option>
                  <option value="Iğdır" data-city="igdir">
                    Iğdır
                  </option>
                  <option value="Isparta" data-city="isparta">
                    Isparta
                  </option>
                  <option value="Kahraman" data-city="kahramanmaras">
                    Kahramanmaraş
                  </option>
                  <option value="Karabük" data-city="karabuk">
                    Karabük
                  </option>
                  <option value="Karaman" data-city="karaman">
                    Karaman
                  </option>
                  <option value="Kars" data-city="kars">
                    Kars
                  </option>
                  <option value="Kastamonu" data-city="kastamonu">
                    Kastamonu
                  </option>
                  <option value="Kayseri" data-city="kayseri">
                    Kayseri
                  </option>
                  <option value="Kırıkkale" data-city="kirikkale">
                    Kırıkkale
                  </option>
                  <option value="Kırklareli" data-city="kirklareli">
                    Kırklareli
                  </option>
                  <option value="Kırşehir" data-city="kirsehir">
                    Kırşehir
                  </option>
                  <option value="Kilis" data-city="kilis">
                    Kilis
                  </option>
                  <option value="Kocaeli" data-city="kocaeli">
                    Kocaeli
                  </option>
                  <option value="Konya" data-city="konya">
                    Konya
                  </option>
                  <option value="Kütahya" data-city="kutahya">
                    Kütahya
                  </option>
                  <option value="Malatya" data-city="malatya">
                    Malatya
                  </option>
                  <option value="Manisa" data-city="manisa">
                    Manisa
                  </option>
                  <option value="Mardin" data-city="mardin">
                    Mardin
                  </option>
                  <option value="Mersin" data-city="mersin">
                    Mersin
                  </option>
                  <option value="Muğla" data-city="mugla">
                    Muğla
                  </option>
                  <option value="Muş" data-city="mus">
                    Muş
                  </option>
                  <option value="Nevşehir" data-city="nevsehir">
                    Nevşehir
                  </option>
                  <option value="Niğde" data-city="nigde">
                    Niğde
                  </option>
                  <option value="Ordu" data-city="ordu">
                    Ordu
                  </option>
                  <option value="Osmaniye" data-city="osmaniye">
                    Osmaniye
                  </option>
                  <option value="Rize" data-city="rize">
                    Rize
                  </option>
                  <option value="Sakarya" data-city="sakarya">
                    Sakarya
                  </option>
                  <option value="Samsun" data-city="samsun">
                    Samsun
                  </option>
                  <option value="Siirt" data-city="siirt">
                    Siirt
                  </option>
                  <option value="Sinop" data-city="sinop">
                    Sinop
                  </option>
                  <option value="Sivas" data-city="sivas">
                    Sivas
                  </option>
                  <option value="Şanlıurfa" data-city="sanliurfa">
                    Şanlıurfa
                  </option>
                  <option value="Şırnak" data-city="sirnak">
                    Şırnak
                  </option>
                  <option value="Tekirdağ" data-city="tekirdag">
                    Tekirdağ
                  </option>
                  <option value="Tokat" data-city="tokat">
                    Tokat
                  </option>
                  <option value="Trabzon" data-city="trabzon">
                    Trabzon
                  </option>
                  <option value="Tunceli" data-city="tunceli">
                    Tunceli
                  </option>
                  <option value="Uşak" data-city="usak">
                    Uşak
                  </option>
                  <option value="Van" data-city="van">
                    Van
                  </option>
                  <option value="Yalova" data-city="yalova">
                    Yalova
                  </option>
                  <option value="Yozgat" data-city="yozgat">
                    Yozgat
                  </option>
                  <option value="Zonguldak" data-city="zonguldak">
                    Zonguldak
                  </option>
                </select>
                <label className="form-label" htmlFor="form-city">
                  Yaşadığınız Şehir
                </label>
              </div>
              <div
                id="phone-field"
                className="col-xs-12 col-md-6 form-field left"
              >
                <InputMask
                  className="focus"
                  id="phone"
                  type="tel"
                  name="phone"
                  placeholder="Cep Telefonu"
                  mask="599 999 99 99"
                  maskChar="_"
                />
                <label className="form-label" htmlFor="phone">
                  Cep Telefonu
                </label>
              </div>
              <div className="col-xs-12 col-md-6 form-field right city-select-field">
                <select
                  name="birthyear"
                  className="select-birthYear"
                  id="birth-year"
                >
                  <option defaultValue></option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                  <option value="1999">1999</option>
                  <option value="1998">1998</option>
                  <option value="1997">1997</option>
                  <option value="1996">1996</option>
                  <option value="1995">1995</option>
                  <option value="1994">1994</option>
                  <option value="1993">1993</option>
                  <option value="1992">1992</option>
                  <option value="1991">1991</option>
                  <option value="1990">1990</option>
                  <option value="1989">1989</option>
                  <option value="1988">1988</option>
                  <option value="1987">1987</option>
                  <option value="1986">1986</option>
                  <option value="1985">1985</option>
                  <option value="1984">1984</option>
                  <option value="1983">1983</option>
                  <option value="1982">1982</option>
                  <option value="1981">1981</option>
                  <option value="1980">1980</option>
                  <option value="1979">1979</option>
                  <option value="1978">1978</option>
                  <option value="1977">1977</option>
                  <option value="1976">1976</option>
                  <option value="1975">1975</option>
                  <option value="1974">1974</option>
                  <option value="1973">1973</option>
                  <option value="1972">1972</option>
                  <option value="1971">1971</option>
                  <option value="1970">1970</option>
                  <option value="1969">1969</option>
                  <option value="1968">1968</option>
                  <option value="1967">1967</option>
                  <option value="1966">1966</option>
                  <option value="1965">1965</option>
                  <option value="1964">1964</option>
                  <option value="1963">1963</option>
                  <option value="1962">1962</option>
                  <option value="1961">1961</option>
                  <option value="1960">1960</option>
                  <option value="1959">1959</option>
                  <option value="1958">1958</option>
                  <option value="1957">1957</option>
                  <option value="1956">1956</option>
                  <option value="1955">1955</option>
                  <option value="1954">1954</option>
                  <option value="1953">1953</option>
                  <option value="1952">1952</option>
                  <option value="1951">1951</option>
                  <option value="1950">1950</option>
                  <option value="1949">1949</option>
                  <option value="1948">1948</option>
                  <option value="1947">1947</option>
                  <option value="1946">1946</option>
                  <option value="1945">1945</option>
                  <option value="1944">1944</option>
                  <option value="1943">1943</option>
                  <option value="1942">1942</option>
                  <option value="1941">1941</option>
                  <option value="1940">1940</option>
                  <option value="1939">1939</option>
                  <option value="1938">1938</option>
                  <option value="1937">1937</option>
                  <option value="1936">1936</option>
                  <option value="1935">1935</option>
                  <option value="1934">1934</option>
                  <option value="1933">1933</option>
                  <option value="1932">1932</option>
                  <option value="1931">1931</option>
                  <option value="1930">1930</option>
                  <option value="1929">1929</option>
                  <option value="1928">1928</option>
                  <option value="1927">1927</option>
                  <option value="1926">1926</option>
                  <option value="1925">1925</option>
                  <option value="1924">1924</option>
                  <option value="1923">1923</option>
                  <option value="1922">1922</option>
                  <option value="1921">1921</option>
                  <option value="1920">1920</option>
                  <option value="1919">1919</option>
                  <option value="1918">1918</option>
                  <option value="1917">1917</option>
                  <option value="1916">1916</option>
                  <option value="1915">1915</option>
                  <option value="1914">1914</option>
                  <option value="1913">1913</option>
                  <option value="1912">1912</option>
                  <option value="1911">1911</option>
                  <option value="1910">1910</option>
                  <option value="1909">1909</option>
                  <option value="1908">1908</option>
                  <option value="1907">1907</option>
                  <option value="1906">1906</option>
                  <option value="1905">1905</option>
                  <option value="1904">1904</option>
                  <option value="1903">1903</option>
                </select>
                <label className="form-label" htmlFor="dogum-yili">
                  Doğum Yılı
                </label>
              </div>
              <p>
                Greenpeace Akdeniz'in kampanya ve destek amaçlı
                bilgilendirmelerinden haberdar olmak istiyor ve bilgilerimin
                Greenpeace Akdeniz veri tabanında saklanmasını onaylıyorum.
              </p>
              <div className="checks">
                <div className="form-field checkbox">
                  <input
                    type="checkbox"
                    id="eposta_check"
                    name="email_permission"
                    value="1"
                    defaultChecked
                  />
                  <label htmlFor="eposta_check">E-Posta</label>
                </div>
                <div className="form-field checkbox">
                  <input
                    type="checkbox"
                    id="sms"
                    name="sms_permission"
                    value="1"
                    defaultChecked
                  />
                  <label htmlFor="sms">SMS</label>
                </div>
                <div className="form-field checkbox">
                  <input
                    type="checkbox"
                    id="telefon_check"
                    name="call_permission"
                    value="1"
                    defaultChecked
                  />
                  <label htmlFor="telefon_check">Telefon</label>
                </div>
              </div>
            </div>
          </div>
          <div className="send">
            <button className="btnSubmit" type="submit">
              İMZA VER{" "}
              <span>
                <i id="num"></i> KİŞİ İMZALADI
              </span>
            </button>
            <div className="send__for-mobile"></div>
          </div>
        </form>
      </div>
    );
  }
}

export default Form;
