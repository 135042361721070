import React from "react";
import $ from "jquery";

import Form from "../../components/form";
import Supporter from "../../components/supporter";

import "./index.scss";

class Hero extends React.Component {
  componentDidMount() {
    $("#citySelect")
      .change(function() {
        var text = $(this)
          .find("option:selected")
          .text();
        var $aux = $("<select/>").append($("<option/>").text(text));
        $(this).after($aux);
        $(this).width($aux.width());
        $aux.remove();
      })
      .change();
  }
  render() {
    return (
      <section id="hero" className="hero">
        <div className="container row between-xs">
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 hero__left">
            <h1 className="hero__title">
              Havada<br></br>Kalmasın!
            </h1>
            <p className="hero__description hero__description--have-city">
              <strong>Nefes demek yaşam demek.</strong> Ne yazık ki hava
              kirliliği artık alarm veriyor. Ekranda gördüğün gri bulutlar
              <span className="select__hero">
                <select name="citySelect" id="citySelect" className="select">
                  <option data-city="İstanbul" value="istanbul">
                    İstanbul
                  </option>
                  <option data-city="Ankara" value="ankara">
                    Ankara
                  </option>
                  <option data-city="İzmir" value="izmir">
                    İzmir
                  </option>
                  <option data-city="Adana" value="adana">
                    Adana
                  </option>
                  <option data-city="Adıyaman" value="adiyaman">
                    Adıyaman
                  </option>
                  <option data-city="Afyonkarahisar" value="afyonkarahisar">
                    Afyonkarahisar
                  </option>
                  <option data-city="Ağrı" value="agri">
                    Ağrı
                  </option>
                  <option data-city="Aksaray" value="aksaray">
                    Aksaray
                  </option>
                  <option data-city="Amasya" value="amasya">
                    Amasya
                  </option>
                  <option data-city="Antalya" value="antalya">
                    Antalya
                  </option>
                  <option data-city="Ardahan" value="ardahan">
                    Ardahan
                  </option>
                  <option data-city="Artvin" value="artvin">
                    Artvin
                  </option>
                  <option data-city="Aydın" value="aydin">
                    Aydın
                  </option>
                  <option data-city="Balıkesir" value="balikesir">
                    Balıkesir
                  </option>
                  <option data-city="Bartın" value="bartin">
                    Bartın
                  </option>
                  <option data-city="Batman" value="batman">
                    Batman
                  </option>
                  <option data-city="Bayburt" value="bayburt">
                    Bayburt
                  </option>
                  <option data-city="Bilecik" value="bilecik">
                    Bilecik
                  </option>
                  <option data-city="Bingöl" value="bingol">
                    Bingöl
                  </option>
                  <option data-city="Bitlis" value="bitlis">
                    Bitlis
                  </option>
                  <option data-city="Bolu" value="bolu">
                    Bolu
                  </option>
                  <option data-city="Burdur" value="burdur">
                    Burdur
                  </option>
                  <option data-city="Bursa" value="bursa">
                    Bursa
                  </option>
                  <option data-city="Çanakkale" value="canakkale">
                    Çanakkale
                  </option>
                  <option data-city="Çankırı" value="cankiri">
                    Çankırı
                  </option>
                  <option data-city="Çorum" value="corum">
                    Çorum
                  </option>
                  <option data-city="Denizli" value="denizli">
                    Denizli
                  </option>
                  <option data-city="Diyarbakır" value="diyarbakir">
                    Diyarbakır
                  </option>
                  <option data-city="Düzce" value="duzce">
                    Düzce
                  </option>
                  <option data-city="Edirne" value="edirne">
                    Edirne
                  </option>
                  <option data-city="Elazığ" value="elazig">
                    Elazığ
                  </option>
                  <option data-city="Erzincan" value="erzincan">
                    Erzincan
                  </option>
                  <option data-city="Erzurum" value="erzurum">
                    Erzurum
                  </option>
                  <option data-city="Eskişehir" value="eskisehir">
                    Eskişehir
                  </option>
                  <option data-city="Gaziantep" value="gaziantep">
                    Gaziantep
                  </option>
                  <option data-city="Giresun" value="giresun">
                    Giresun
                  </option>
                  <option data-city="Gümüşhane" value="gumushane">
                    Gümüşhane
                  </option>
                  <option data-city="Hakkâri" value="hakkari">
                    Hakkâri
                  </option>
                  <option data-city="Hatay" value="hatay">
                    Hatay
                  </option>
                  <option data-city="Iğdır" value="igdir">
                    Iğdır
                  </option>
                  <option data-city="Isparta" value="isparta">
                    Isparta
                  </option>
                  <option data-city="Kahraman" value="kahramanmaras">
                    Kahramanmaraş
                  </option>
                  <option data-city="Karabük" value="karabuk">
                    Karabük
                  </option>
                  <option data-city="Karaman" value="karaman">
                    Karaman
                  </option>
                  <option data-city="Kars" value="kars">
                    Kars
                  </option>
                  <option data-city="Kastamonu" value="kastamonu">
                    Kastamonu
                  </option>
                  <option data-city="Kayseri" value="kayseri">
                    Kayseri
                  </option>
                  <option data-city="Kırıkkale" value="kirikkale">
                    Kırıkkale
                  </option>
                  <option data-city="Kırklareli" value="kirklareli">
                    Kırklareli
                  </option>
                  <option data-city="Kırşehir" value="kirsehir">
                    Kırşehir
                  </option>
                  <option data-city="Kilis" value="kilis">
                    Kilis
                  </option>
                  <option data-city="Kocaeli" value="kocaeli">
                    Kocaeli
                  </option>
                  <option data-city="Konya" value="konya">
                    Konya
                  </option>
                  <option data-city="Kütahya" value="kutahya">
                    Kütahya
                  </option>
                  <option data-city="Malatya" value="malatya">
                    Malatya
                  </option>
                  <option data-city="Manisa" value="manisa">
                    Manisa
                  </option>
                  <option data-city="Mardin" value="mardin">
                    Mardin
                  </option>
                  <option data-city="Mersin" value="mersin">
                    Mersin
                  </option>
                  <option data-city="Muğla" value="mugla">
                    Muğla
                  </option>
                  <option data-city="Muş" value="mus">
                    Muş
                  </option>
                  <option data-city="Nevşehir" value="nevsehir">
                    Nevşehir
                  </option>
                  <option data-city="Niğde" value="nigde">
                    Niğde
                  </option>
                  <option data-city="Ordu" value="ordu">
                    Ordu
                  </option>
                  <option data-city="Osmaniye" value="osmaniye">
                    Osmaniye
                  </option>
                  <option data-city="Rize" value="rize">
                    Rize
                  </option>
                  <option data-city="Sakarya" value="sakarya">
                    Sakarya
                  </option>
                  <option data-city="Samsun" value="samsun">
                    Samsun
                  </option>
                  <option data-city="Siirt" value="siirt">
                    Siirt
                  </option>
                  <option data-city="Sinop" value="sinop">
                    Sinop
                  </option>
                  <option data-city="Sivas" value="sivas">
                    Sivas
                  </option>
                  <option data-city="Şanlıurfa" value="sanliurfa">
                    Şanlıurfa
                  </option>
                  <option data-city="Şırnak" value="sirnak">
                    Şırnak
                  </option>
                  <option data-city="Tekirdağ" value="tekirdag">
                    Tekirdağ
                  </option>
                  <option data-city="Tokat" value="tokat">
                    Tokat
                  </option>
                  <option data-city="Trabzon" value="trabzon">
                    Trabzon
                  </option>
                  <option data-city="Tunceli" value="tunceli">
                    Tunceli
                  </option>
                  <option data-city="Uşak" value="usak">
                    Uşak
                  </option>
                  <option data-city="Van" value="van">
                    Van
                  </option>
                  <option data-city="Yalova" value="yalova">
                    Yalova
                  </option>
                  <option data-city="Yozgat" value="yozgat">
                    Yozgat
                  </option>
                  <option data-city="Zonguldak" value="zonguldak">
                    Zonguldak
                  </option>
                  <option value="diger">Diğer</option>
                </select>
              </span>
              için yıllık hava kirliliği ortalamasını gösteriyor. Havamızı
              kirleten en tehlikeli madde PM 2.5 için ülkemizde henüz sınır
              değer yok.
              <br />
              <strong>Temiz hava hakkı için hemen kampanyamıza katıl!</strong>
            </p>
            <p className="hero__description hero__description--no-city remove">
              Nefes demek yaşam demek. Ne yazık ki hava kirliliği artık alarm
              veriyor. Havayı kirleten maddelerin en tehlikelisi PM 2.5 ve
              maalesef Türkiye’de PM 2.5 için yasal bir sınır değer yok. Bu
              değer hemen belirlenmeli! Sen de “havada kalmasın” diyerek
              harekete geç, derin bir nefes alalım!
              <span className="hero__space"></span>
              Dilersen bulunduğun şehri seçerek şehrinin değerlerini
              görüntüleyebilirsin.
              <span className="select__hero">
                <select name="citySelect" id="citySelect2" className="select">
                  <option value="noCity">Şehir Seç</option>
                  <option data-city="İstanbul" value="istanbul">
                    İstanbul
                  </option>
                  <option data-city="Ankara" value="ankara">
                    Ankara
                  </option>
                  <option data-city="İzmir" value="izmir">
                    İzmir
                  </option>
                  <option data-city="Adana" value="adana">
                    Adana
                  </option>
                  <option data-city="Adıyaman" value="adiyaman">
                    Adıyaman
                  </option>
                  <option data-city="Afyonkarahisar" value="afyonkarahisar">
                    Afyonkarahisar
                  </option>
                  <option data-city="Ağrı" value="agri">
                    Ağrı
                  </option>
                  <option data-city="Aksaray" value="aksaray">
                    Aksaray
                  </option>
                  <option data-city="Amasya" value="amasya">
                    Amasya
                  </option>
                  <option data-city="Antalya" value="antalya">
                    Antalya
                  </option>
                  <option data-city="Ardahan" value="ardahan">
                    Ardahan
                  </option>
                  <option data-city="Artvin" value="artvin">
                    Artvin
                  </option>
                  <option data-city="Aydın" value="aydin">
                    Aydın
                  </option>
                  <option data-city="Balıkesir" value="balikesir">
                    Balıkesir
                  </option>
                  <option data-city="Bartın" value="bartin">
                    Bartın
                  </option>
                  <option data-city="Batman" value="batman">
                    Batman
                  </option>
                  <option data-city="Bayburt" value="bayburt">
                    Bayburt
                  </option>
                  <option data-city="Bilecik" value="bilecik">
                    Bilecik
                  </option>
                  <option data-city="Bingöl" value="bingol">
                    Bingöl
                  </option>
                  <option data-city="Bitlis" value="bitlis">
                    Bitlis
                  </option>
                  <option data-city="Bolu" value="bolu">
                    Bolu
                  </option>
                  <option data-city="Burdur" value="burdur">
                    Burdur
                  </option>
                  <option data-city="Bursa" value="bursa">
                    Bursa
                  </option>
                  <option data-city="Çanakkale" value="canakkale">
                    Çanakkale
                  </option>
                  <option data-city="Çankırı" value="cankiri">
                    Çankırı
                  </option>
                  <option data-city="Çorum" value="corum">
                    Çorum
                  </option>
                  <option data-city="Denizli" value="denizli">
                    Denizli
                  </option>
                  <option data-city="Diyarbakır" value="diyarbakir">
                    Diyarbakır
                  </option>
                  <option data-city="Düzce" value="duzce">
                    Düzce
                  </option>
                  <option data-city="Edirne" value="edirne">
                    Edirne
                  </option>
                  <option data-city="Elazığ" value="elazig">
                    Elazığ
                  </option>
                  <option data-city="Erzincan" value="erzincan">
                    Erzincan
                  </option>
                  <option data-city="Erzurum" value="erzurum">
                    Erzurum
                  </option>
                  <option data-city="Eskişehir" value="eskisehir">
                    Eskişehir
                  </option>
                  <option data-city="Gaziantep" value="gaziantep">
                    Gaziantep
                  </option>
                  <option data-city="Giresun" value="giresun">
                    Giresun
                  </option>
                  <option data-city="Gümüşhane" value="gumushane">
                    Gümüşhane
                  </option>
                  <option data-city="Hakkâri" value="hakkari">
                    Hakkâri
                  </option>
                  <option data-city="Hatay" value="hatay">
                    Hatay
                  </option>
                  <option data-city="Iğdır" value="igdir">
                    Iğdır
                  </option>
                  <option data-city="Isparta" value="isparta">
                    Isparta
                  </option>
                  <option data-city="Kahraman" value="kahramanmaras">
                    Kahramanmaraş
                  </option>
                  <option data-city="Karabük" value="karabuk">
                    Karabük
                  </option>
                  <option data-city="Karaman" value="karaman">
                    Karaman
                  </option>
                  <option data-city="Kars" value="kars">
                    Kars
                  </option>
                  <option data-city="Kastamonu" value="kastamonu">
                    Kastamonu
                  </option>
                  <option data-city="Kayseri" value="kayseri">
                    Kayseri
                  </option>
                  <option data-city="Kırıkkale" value="kirikkale">
                    Kırıkkale
                  </option>
                  <option data-city="Kırklareli" value="kirklareli">
                    Kırklareli
                  </option>
                  <option data-city="Kırşehir" value="kirsehir">
                    Kırşehir
                  </option>
                  <option data-city="Kilis" value="kilis">
                    Kilis
                  </option>
                  <option data-city="Kocaeli" value="kocaeli">
                    Kocaeli
                  </option>
                  <option data-city="Konya" value="konya">
                    Konya
                  </option>
                  <option data-city="Kütahya" value="kutahya">
                    Kütahya
                  </option>
                  <option data-city="Malatya" value="malatya">
                    Malatya
                  </option>
                  <option data-city="Manisa" value="manisa">
                    Manisa
                  </option>
                  <option data-city="Mardin" value="mardin">
                    Mardin
                  </option>
                  <option data-city="Mersin" value="mersin">
                    Mersin
                  </option>
                  <option data-city="Muğla" value="mugla">
                    Muğla
                  </option>
                  <option data-city="Muş" value="mus">
                    Muş
                  </option>
                  <option data-city="Nevşehir" value="nevsehir">
                    Nevşehir
                  </option>
                  <option data-city="Niğde" value="nigde">
                    Niğde
                  </option>
                  <option data-city="Ordu" value="ordu">
                    Ordu
                  </option>
                  <option data-city="Osmaniye" value="osmaniye">
                    Osmaniye
                  </option>
                  <option data-city="Rize" value="rize">
                    Rize
                  </option>
                  <option data-city="Sakarya" value="sakarya">
                    Sakarya
                  </option>
                  <option data-city="Samsun" value="samsun">
                    Samsun
                  </option>
                  <option data-city="Siirt" value="siirt">
                    Siirt
                  </option>
                  <option data-city="Sinop" value="sinop">
                    Sinop
                  </option>
                  <option data-city="Sivas" value="sivas">
                    Sivas
                  </option>
                  <option data-city="Şanlıurfa" value="sanliurfa">
                    Şanlıurfa
                  </option>
                  <option data-city="Şırnak" value="sirnak">
                    Şırnak
                  </option>
                  <option data-city="Tekirdağ" value="tekirdag">
                    Tekirdağ
                  </option>
                  <option data-city="Tokat" value="tokat">
                    Tokat
                  </option>
                  <option data-city="Trabzon" value="trabzon">
                    Trabzon
                  </option>
                  <option data-city="Tunceli" value="tunceli">
                    Tunceli
                  </option>
                  <option data-city="Uşak" value="usak">
                    Uşak
                  </option>
                  <option data-city="Van" value="van">
                    Van
                  </option>
                  <option data-city="Yalova" value="yalova">
                    Yalova
                  </option>
                  <option data-city="Yozgat" value="yozgat">
                    Yozgat
                  </option>
                  <option data-city="Zonguldak" value="zonguldak">
                    Zonguldak
                  </option>
                </select>
              </span>
            </p>
            <div className="bar">
              <div className="bar__container" data-progress-percent="0">
                <div className="bar__bar">
                  <div className="green">11</div>
                  <div className="yellow">35.5</div>
                  <div className="orange">55.5</div>
                  <div className="red">90</div>
                </div>
                <div className="bar__arrow">
                  <img
                    alt="Bar 27"
                    src="/images/bar-line.svg"
                  />
                  <span>
                    <strong>0</strong> µg/m³
                  </span>
                </div>
              </div>
              <div className="bar__text">
                <span>
                  PM 2.5 <abbr title="Dünya Sağlık Örgütü">DSÖ*</abbr> Sınır
                  Değer: <strong>10</strong> µg/m³
                </span>
              </div>
              <div className="undetermine" style={{ display: "none" }}>
                <img
                  src="/images/icon-danger.svg"
                  alt="Yetersiz Ölçüm"
                  width="24"
                />
                <span>
                  Seçtiğiniz ilin puanı yetersiz ölçüm nedeniyle
                  hesaplanamamıştır.
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4 hero__right">
            <Form></Form>
            <Supporter></Supporter>
            <ul className="last-signed"></ul>
          </div>

          <div className="imageMask"></div>
          <div className="cities">
            <div className="currentImage"></div>
          </div>
          <div className="clouds">
            <div id="clouds_level_01" className="currentCloud"></div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
