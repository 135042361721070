import React from "react";
import "./index.scss";

export default class Supporter extends React.Component {
  render() {
    return (
      <div className="supporter">
        <div className="supporter__content">
          <div className="supporter__reset">
            ( <span>Ben değilim</span> )
          </div>
          <p className="supporter__text">
            <div className="supporter__top">
              Merhaba<span className="supporter__name"></span>,
            </div>
            Temiz hava hakkım havada kalmasın dedin. Şimdi kampanyayı
            arkadaşlarınla <strong>paylaş, duymayan kalmasın.</strong>
          </p>
          <hr className="supporter__line" />
          <p className="supporter__text">
            KAMPANYAYI{" "}
            <strong>
              <i id="num2"></i> KİŞİ İMZALADI
            </strong>
          </p>
        </div>
        <div className="supporter__footer">
          <a className="supporter__url" href="/">
            DESTEĞİNE DEVAM ET
          </a>
        </div>
      </div>
    );
  }
}
